@tailwind base;
@tailwind components;
@tailwind utilities;

*{  
    margin: 0;
    padding:0;
    box-sizing: border-box;
}

body::-webkit-scrollbar {
    display: none;
}


.arrow_down {
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #fff;
}

.loginSidebar {
    background-image: url("./assets/images/bookshop.svg");
    background-size: contain;
    background-position: center 85%;
    background-repeat: no-repeat;
}


@font-face {
    font-family: "POPPINS BOLD";
    src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
    font-display: block;
}

@font-face {
    font-family: "POPPINS REGULAR";
    src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
    font-display: block;
}

@font-face {
    font-family: "POPPINS MEDIUM";
    src: url("./assets/fonts/Poppins/Poppins-Medium.ttf");
    font-display: block;
}

@font-face {
    font-family: "POPPINS SEMIBOLD";
    src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf");
    font-display: block;
}

@font-face {
    font-family: "POPPINS LIGHT";
    src: url("./assets/fonts/Poppins/Poppins-Light.ttf");
    font-display: block;
}

@font-face {
    font-family: "POPPINS THIN";
    src: url("./assets/fonts/Poppins/Poppins-Thin.ttf");
    font-display: block;
}

@font-face {
    font-family: "POPPINS EXTRA LIGHT";
    src: url("./assets/fonts/Poppins/Poppins-ExtraLight.ttf");
    font-display: block;
}

@font-face {
    font-family: "POPPINS EXTRA BOLD";
    src: url("./assets/fonts/Poppins/Poppins-ExtraBold.ttf");
    font-display: block;
}

@font-face {
    font-family: "POPPINS BLACK";
    src: url("./assets/fonts/Poppins/Poppins-Black.ttf");
    font-display: block;
}

@font-face {
    font-family: "POPPINS ITALIC";
    src: url("./assets/fonts/Poppins/Poppins-Italic.ttf");
    font-display: block;
}

@font-face {
    font-family: "POPPINS LIGHT ITALIC";
    src: url("./assets/fonts/Poppins/Poppins-LightItalic.ttf");
    font-display: block;
}

@font-face {
    font-family: "POPPINS MEDIUM ITALIC";
    src: url("./assets/fonts/Poppins/Poppins-MediumItalic.ttf");
    font-display: block;
}

@font-face {
    font-family: "POPPINS SEMIBOLD ITALIC";
    src: url("./assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf");
    font-display: block;
}
